<template>
  <div>
    <!-- 宽带预约 -->
    
    <!-- 搜索栏 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">      
      <el-form-item>
        <el-select v-model="queryParams.status" clearable
        placeholder="全部状态" @change="resetPageAndRefresh">
          <el-option label="未处理" :value="1"></el-option>
          <el-option label="已处理" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.account" clearable placeholder="学号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" icon="el-icon-search"  @click="refresh">搜索</el-button>
      </el-form-item>      
    </el-form>

    <div class="content-wrap">
      <!-- <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" >添加套餐</el-button>
      </div> -->
      <!--列表-->
      <paged-table
        :data="applyList"
        :total="applyTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh = "refresh"
      >
        <el-table-column
          prop="name"
          label="用户"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="account"
          label="学号"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="room"
          label="宿舍号"
          :show-overflow-tooltip="true"
        >
        </el-table-column>        
        <el-table-column
          prop="addTime"
          label="下单时间"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
               
        <el-table-column prop="status"
          label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">未处理</span>
          <span v-if="scope.row.status == 2">已处理</span>
        </template>
        </el-table-column>

        <el-table-column
          prop="updTime"
          label="处理时间"
          :show-overflow-tooltip="true"
        >
        </el-table-column> 

        <el-table-column
          prop="result"
          label="处理结果"
          :show-overflow-tooltip="true"
        >
        </el-table-column> 

        <el-table-column :align="center" label="操作"
          width="120">
          <template slot-scope="scope">
            <el-button size="mini" v-if="scope.row.status == 1" type="primary" @click="handleOrder(scope.row), orderVisible = true"
              icon="el-icon-s-order" title="处理"></el-button>            
          </template>
        </el-table-column>
      </paged-table>
    </div>
    

    <!-- 派单 -->
    <el-dialog title="处理" :visible.sync="orderVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="110px" size="small">
        <el-form-item label="处理结果" style="width: 90%">
          <el-input v-model="orderForm.result" clearable placeholder="输入处理结果"></el-input>
        </el-form-item>
        <el-form-item style="width: 60%">
          <el-button size="small" @click="orderVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitOrder">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'apply',
  components: {
    PagedTable
  },
  mixins: [pageMixin],
  data() {
    return {
      BaseUrl,
      queryParams: {
        status: '',        
        account: ''
      },      
      orderVisible: false,
      orderForm: {
        id: null,
        result: null,
        status: 2
      },
      orderOption: [],
      pageApi:'getApplyList',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("apply", ["applyList", "applyTotal"]),
  },
  methods: {
    ...mapActions("apply",[ "getApplyList", "getApplyEdit" ]),
    async handleOrder(row) {
      this.orderForm.id = row.id
      this.orderForm.status = 2      
    },
    async submitOrder() {
      if(!this.orderForm.result){
        return;
      }else{
        try{
          let res = await this.getApplyEdit({ ...this.orderForm })
          if(!!res){ this.$message.success("处理成功") }
        }catch(error){}finally{
          this.orderVisible = false
          this.refresh()
        }
      }
    },    
  },
  mounted(){
    this.refresh()
  }
};
</script>
<style lang="scss" scoped>
.lable-num { width: 100px; text-align: center; background: #28B892; color: #fff; border-radius: 5px; }
</style>
